import type { PageLoad } from "./$types";
import { siteConfig } from "$lib/config";

export const load: PageLoad = async () => {
    return {
        meta: {
            title: "Home",
            description: siteConfig.tagline,
            postDetails: {
                slug: "/",
                keywords: [],
                categories: [],
                tags: [],
                featuredImage: undefined,
            }
        }
    }
};